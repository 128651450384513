
function mobile() {

    /** ===========================================
        Hide / show the master navigation menu
    ============================================ */

    // console.log('Window Height is: ' + $(window).height());
    // console.log('Document Height is: ' + $(document).height());

    var previousScroll = 0;

    $(window).scroll(function(){

        var currentScroll = $(this).scrollTop();

        /*
          If the current scroll position is greater than 0 (the top) AND the current scroll position is less than the document height minus the window height (the bottom) run the navigation if/else statement.
        */
        if (currentScroll > 0 && currentScroll < $(document).height() - $(window).height()){
          /*
            If the current scroll is greater than the previous scroll (i.e we're scrolling down the page), hide the nav.
          */
          if (currentScroll > previousScroll){
            hideNav();
          /*
            Else we are scrolling up (i.e the previous scroll is greater than the current scroll), so show the nav.
          */
          } else showNav();
          /* 
            Set the previous scroll value equal to the current scroll.
          */
          previousScroll = currentScroll;
        }

    });

}

function tablet() {}
function desktop() {}
function tablet_n_mobile() {}
function tablet_n_desktop() {}

function hideNav() {
    TweenMax.to('nav', 1, {
        //opacity: 0,
        y: -70
    });
}
function showNav() {
    TweenMax.to('nav', .5, {
        //opacity: 1,
        y: 0
    });
}

$(function () {

	var $nav = $('nav, .side-nav');

	var $header = $('header');
	var $s1 = $('#comofunciona');
	var $s2 = $('#passageiros');
	var $s3 = $('#s3');
	var $s4 = $('#responsaveis');
	var $s5 = $('#s5');
	var $s6 = $('#transportadores');
	var $s7 = $('#s7');
	var $s8 = $('#s8');
	var $s9 = $('#s9');
	var $s10 = $('#contato');
	var $footer = $('footer');

	var $max = 992;
	var $min = 600;

    var $is_mobile = false;
    var $is_tablet = false;
    var $is_desktop = false;

    if($(window).width() <= $min) 
    	{ mobile(); tablet_n_mobile(); $is_mobile = true; }
    else 
    if($(window).width() <= $max) 
    	{ tablet(); tablet_n_mobile(); tablet_n_desktop(); $is_tablet = true; } 
    else 
    	{ desktop(); tablet_n_desktop(); $is_desktop = true; }

    //------------------------------------------------
    //Navbar -----------------------------------------

    $(".button-collapse").sideNav();

    $('.side-nav a').click(function(){
        $('.button-collapse').sideNav('hide');
    });

    $('#inicio a').click(function() {
        TweenLite.to(window, 2, {
            scrollTo:{ y: $('header').position().top }, 
            ease: Power2.easeOut, 
            delay: $is_desktop ? 0 : .4
        });
    });

    $('#como-funciona a').click(function() {
        TweenLite.to(window, 2, {
            scrollTo:{ y: $s1.position().top }, 
            ease: Power2.easeOut, 
            delay: $is_desktop ? 0 : .4
        });
    });

    $('#alunos a').click(function() {
        TweenLite.to(window, 2, {
            scrollTo:{ y: $s2.position().top }, 
            ease: Power2.easeOut, 
            delay: $is_desktop ? 0 : .4
        });
    });

    $('#responsaveis-link a').click(function() {
        TweenLite.to(window, 2, {
            scrollTo:{ y: $s4.position().top }, 
            ease: Power2.easeOut, 
            delay: $is_desktop ? 0 : .4
        });
    });

    $('#transportadores-link a').click(function() {
        TweenLite.to(window, 2, {
            scrollTo:{ y: $s6.position().top + 100 }, 
            ease: Power2.easeOut, 
            delay: $is_desktop ? 0 : .4
        });
    });

    $('#gestor-de-frotas a').click(function() {
        TweenLite.to(window, 2, {
            scrollTo:{ y: $('#s7').position().top }, 
            ease: Power2.easeOut, 
            delay: $is_desktop ? 0 : .4
        });
    });

    $('#contato-link a').click(function() {
        TweenLite.to(window, 2, {
            scrollTo:{ y: $('#contato').position().top }, 
            ease: Power2.easeOut, 
            delay: $is_desktop ? 0 : .4
        });
    });

    //------------------------------------------------
    //Header -----------------------------------------
    $header.find('.parallax').parallax();

    var tween1 = TweenMax.from('header .iterator#i1', 1.5, {
        opacity: 0,
        x: -100
    });

    var tween2 = TweenMax.from('header .iterator#i2', 1.5, {
        opacity: 0,
        x: $is_desktop ? 100 : 0,
        y: $is_desktop ? 0 : 50
    });

    var tween3 = TweenMax.staggerFrom('header .iterator#i3', 1, {
        opacity: 0,
        y: 100,
        rotation: 90,
        scale: .5,
        delay: .5
    }, 0.3);


    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3]);

    new ScrollMagic.Scene({
        triggerElement: 'header'
    })
    .on('enter', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#inicio').addClass('active');
        history.pushState("", document.title, "#comeco");
    })
    .on('leave', function () {
        $nav.find('li').removeClass('active');
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 1 --------------------------------------
    var $play = $s1.find('.play');
    var $video = $s1.find('.video');

    $play.find('img').hover(
        function() {
            $(this).attr('src', 'landingpage/img/play-h.png');
        },
        function() {
            $(this).attr('src', 'landingpage/img/play.png');
        }
    ).click(function() {
        $(this).addClass('animated rubberBand');
        $play.removeClass('z-index-1');
        $video.removeClass('hide');

        TweenLite.to($(this), 1, { opacity: 0, delay:1 });
        TweenLite.to($video, 0.5, { opacity: 1, delay:1 });
        TweenLite.from($video, 2, { y: -1000, delay:1, ease: Bounce.easeOut });

        $video.find('iframe').attr('src', $video.find('.src').data('src'));
    });

    var tween1 = TweenMax.from('#comofunciona .iterator#i1', 1, {
        y:50
    });

    var tween2 = TweenMax.from('#comofunciona .iterator#i2', 1, {
        x: $is_desktop ? -100 : 0,
        delay:1
    });

    var tween3 = TweenMax.from('#comofunciona .iterator#i3', 1, {
        x: $is_desktop ? 100 : 0,
        delay:1
    });

    var tween4 = TweenMax.from('#comofunciona .iterator#i4', 2.5, {
        scale:0,
        delay:.5,
        ease: Elastic.easeOut.config(1, 0.3)
    });

    var tween5 = TweenMax.from('#comofunciona .iterator#i5', 1, {
        delay:.5
    });

    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3, tween4, tween5]);

    new ScrollMagic.Scene({
        triggerElement: '#comofunciona'
    })
    .on('enter', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#como-funciona').addClass('active');
        history.pushState("", document.title, "#comofunciona");
    })
    .on('leave', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#inicio').addClass('active');
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 2 --------------------------------------
    var tween1 = TweenMax.from('#passageiros .iterator#i1', 1, {
        opacity: 0,
        x: $is_desktop ? -50 : 0,
        y: $is_desktop ? 0 : 50
    });

    var tween2 = TweenMax.from('#passageiros .iterator#i2', 1, {
        opacity: 0
    });

    var bubble1 = TweenMax.from('#passageiros .bubble#b1', 2, {
        opacity: 0,
        scaleY: 0,
        y: $is_desktop ? 50 : -50,
        delay: 1,
        ease: Power4.easeOut
    });

    var timeline = new TimelineMax()
        .add([tween1, tween2, bubble1]);

    new ScrollMagic.Scene({
        triggerElement: '#passageiros'
    })
    .on('enter', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#alunos').addClass('active');
        history.pushState("", document.title, "#passageiros");
    })
    .on('leave', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#como-funciona').addClass('active');
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 3 --------------------------------------
    var tween1 = TweenMax.from('#s3 .iterator#i1', 1, {
        opacity: 0,
        x: $is_desktop ? 50 : 0,
        y: $is_desktop ? 0 : 50
    });

    var tween2 = TweenMax.from('#s3 .iterator#i2', 1, {
        opacity: 0,
        x: -50
    });

    var timeline = new TimelineMax()
        .add([tween1, tween2]);

    new ScrollMagic.Scene({
        triggerElement: '#s3'
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 4 --------------------------------------
    var tween1 = TweenMax.from('#responsaveis .iterator#i1', 1, {
        opacity: 0
    });

    var tween2 = TweenMax.from('#responsaveis .iterator#i2', 1, {
        opacity: 0,
        x: $is_desktop ? 50 : 0,
        y: $is_desktop ? 0 : 50,
    });

    var tween3 = TweenMax.from('#responsaveis .iterator#i3', 1, {
        opacity: 0,
        scale: .5,
        delay: 1.2,
        ease: Back.easeOut.config(1.7)
    });

    if($is_desktop || $is_tablet) {

        var bubble1 = TweenMax.from('#responsaveis .bubble#b1', 2, {
            opacity: 0,
            scaleX: 0,
            x: 150,
            delay: 2,
            ease: Power4.easeOut
        });

        var bubble2 = TweenMax.from('#responsaveis .bubble#b2', 2, {
            opacity: 0,
            scaleY: 0,
            y: 70,
            delay: 2.4,
            ease: Power4.easeOut
        });

        var bubble3 = TweenMax.from('#responsaveis .bubble#b3', 2, {
            opacity: 0,
            scaleY: 0,
            y: -70,
            delay: 2.7,
            ease: Power4.easeOut
        });

        var bubble4 = TweenMax.from('#responsaveis .bubble#b4', 2, {
            opacity: 0,
            scaleX: 0,
            x: -150,
            delay: 2.9,
            ease: Power4.easeOut
        });

    } else {

        var bubble1 = TweenMax.from('#responsaveis .bubble#b1', 2, {
            opacity: 0,
            scaleY: 0,
            y: 50,
            delay: 2.5,
            ease: Power4.easeOut
        });

        var bubble2 = TweenMax.from('#responsaveis .bubble#b2', 2, {
            opacity: 0,
            scaleY: 0,
            y: 50,
            delay: 2.9,
            ease: Power4.easeOut
        });

        var bubble3 = TweenMax.from('#responsaveis .bubble#b3', 2, {
            opacity: 0,
            scaleY: 0,
            y: -50,
            delay: 3.2,
            ease: Power4.easeOut
        });

        var bubble4 = TweenMax.from('#responsaveis .bubble#b4', 2, {
            opacity: 0,
            scaleY: 0,
            y: -50,
            delay: 3.4,
            ease: Power4.easeOut
        });

    }    

    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3, bubble1, bubble2, bubble3, bubble4]);

    new ScrollMagic.Scene({
        triggerElement: '#responsaveis'
    })
    .on('enter', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#responsaveis-link').addClass('active');
        history.pushState("", document.title, "#responsaveis");
    })
    .on('leave', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#alunos').addClass('active');
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 5 --------------------------------------
    var tween1 = TweenMax.from('#s5 .iterator#i1', 1, {
        opacity: 0,
        x: $is_desktop ? -50 : 0,
        y: $is_desktop ? 0 : 50
    });

    var tween2 = TweenMax.from('#s5 .iterator#i2', 1, {
        opacity: 0,
        x: $is_desktop ? 50 : 0,
        y: $is_desktop ? 0 : 50
    });

    var tween3 = TweenMax.from('#s5 .iterator#i3', 1, {
        opacity: 0,
        scale: .5,
        delay: .2,
        ease: Back.easeOut.config(1.5)
    });

    var tween4 = TweenMax.from('#s5 .iterator#i4', 1, {
        opacity: 0,
        delay: .4
    });

    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3, tween4]);

    new ScrollMagic.Scene({
        triggerElement: '#s5'
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 6 --------------------------------------
    var tween1 = TweenMax.from('#transportadores .iterator#i1', 1, {
        opacity: 0,
        scale: .5
    });

    var tween2 = TweenMax.from('#transportadores .iterator#i2', 1, {
        opacity: 0
    });

    var tween3 = TweenMax.from('#transportadores .iterator#i3', 2, {
        opacity: 0,
        x: $is_desktop ? 300 : 0,
        scale: $is_desktop ? 1 : .5,
        delay: 2.5,
        ease: Power4.easeOut
    });

    var tween4 = TweenMax.from('#transportadores .iterator#i4', 2, {
        opacity: 0,
        x: $is_desktop ? -300 : 0,
        scale: $is_desktop ? 1 : .5,
        delay: 4,
        ease: Power4.easeOut
    });

    var tween5 = TweenMax.from('#transportadores .iterator#i5', 1, {
        opacity: 0,
        rotation: 45,
        y: 100,
        delay: 5.5
    });

    var bubble1 = TweenMax.from('#transportadores .bubble#b1', 2, {
        opacity: 0,
        scaleY: 0,
        y: 60,
        delay: 1.5,
        ease: Power4.easeOut
    });

    var bubble2 = TweenMax.from('#transportadores .bubble#b2', 2, {
        opacity: 0,
        scaleX: 0,
        x: -150,
        delay: 3,
        ease: Power4.easeOut
    });

    var bubble3 = TweenMax.from('#transportadores .bubble#b3', 2, {
        opacity: 0,
        scaleX: 0,
        x: 150,
        delay: 4.5,
        ease: Power4.easeOut
    });

    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3, tween4, tween5, bubble1, bubble2, bubble3]);

    new ScrollMagic.Scene({
        triggerElement: '#transportadores'
    })
    .on('enter', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#transportadores-link').addClass('active');
        history.pushState("", document.title, "#transportadores");
    })
    .on('leave', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#responsaveis-link').addClass('active');
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 7 --------------------------------------
    var tween1 = TweenMax.from('#s7 .iterator#i1', 1, {
        opacity: 0,
        y: 50
    });

    var tween2 = TweenMax.from('#s7 .iterator#i2', 1, {
        opacity: 0,
        delay: .5,
        x: $is_desktop ? -100 : 50
    });

    var tween3 = TweenMax.from('#s7 .iterator#i3', 1, {
        opacity: 0,
        scale: .5,
        ease: Back.easeOut.config(1.8)
    });

    var tween4 = TweenMax.from('#s7 .iterator#i4', 1, {
        opacity: 0,
        delay: .5,
        x: $is_desktop ? 100 : -50
    });

    var tween5 = TweenMax.staggerFrom('#s7 .iterator#i5', 1, {
        opacity: 0,
        delay: 1,
        y: 100
    }, 0.3);

    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3, tween4, tween5]);

    new ScrollMagic.Scene({
        triggerElement: '#s7'
    })
    .on('enter', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#gestor-de-frotas').addClass('active');
    })
    .on('leave', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#transportadores-link').addClass('active');
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section Transition -----------------------------

    var tween1 = TweenMax.to('#st .iterator#i1', 2, {
        scale: 1.2,
        x: $(window).width(),
        y: $(window).width() * .05,
        ease: Power0.easeNone
    });

    var timeline = new TimelineMax()
        .add([tween1]);

    new ScrollMagic.Scene({
        duration: $(window).height() * .7,
        offset: -$(window).height() * .5,
        triggerElement: '#s8'
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 8 --------------------------------------
    var tween1 = TweenMax.from('#s8 .iterator#i1', 1, {
        opacity: 0,
        x: $is_desktop ? -100 : 0,
        y: $is_desktop ? 0 : 50,
        delay: $is_mobile ? 2.5 : 0
    });

    var tween2 = TweenMax.from('#s8 .iterator#i2', 1, {
        opacity: 0,
        y:50,
        delay: .5
    });

    var tween3 = TweenMax.staggerFrom('#s8 .iterator#i3', 1, {
        opacity: 0,
        y: 100,
        rotation: 90,
        scale: .5,
        delay: 1
    }, 0.3);

    var tween4 = TweenMax.from('#s8 .iterator#i4', 1, {
        opacity: 0,
        x: $is_desktop ? 200 : 0,
        y: $is_desktop ? 0 : 100,
        delay: $is_mobile ? 2.5 : 0
    });

    var tween5 = TweenMax.from('#s8 .iterator#i5', 1, {
        opacity: 0,
        x: $is_desktop ? 100 : 0,
        y: $is_desktop ? 0 : 50,
        delay: $is_mobile ? 2.5 : 0
    });

    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3, tween4, tween5]);

    new ScrollMagic.Scene({
        triggerElement: '#s8'
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 9 --------------------------------------
    $("#s9 .owl-carousel").owlCarousel({
        items: 3,
        itemsTabletSmall: true,
        autoPlay: true,
        scrollPerPage: true
    });

    var tween1 = TweenMax.from('#s9 .iterator#i1', 1, {
        opacity: 0,
        y: 50
    });

    var tween2 = TweenMax.staggerFrom('#s9 .iterator#i2', 1, {
        opacity: 0,
        scale: 0,
        rotation: -360,
        delay: .5,
        y: 100
    }, 0.3);

    var tween3 = TweenMax.staggerFrom('#s9 .iterator#i3', 1, {
        opacity: 0,
        y: 100
    }, 0.3);

    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3]);

    new ScrollMagic.Scene({
        triggerElement: '#s9'
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //------------------------------------------------
    //Section 10 -------------------------------------
    var tween1 = TweenMax.from('#contato .iterator#i1', 1, {
        opacity: 0,
        y: 50
    });

    var tween2 = TweenMax.from('#contato .iterator#i2', 1, {
        opacity: 0,
        scale: .5,
        x:-100,
        delay: .5
    });

    var tween3 = TweenMax.from('#contato .iterator#i3', 1, {
        opacity: 0,
        x: $is_desktop ? 100 : 0,
        y: $is_desktop ? 0 : 50,
        delay: 1
    });

    var timeline = new TimelineMax()
        .add([tween1, tween2, tween3]);

    new ScrollMagic.Scene({
        triggerElement: '#contato'
    })
    .on('enter', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#contato-link').addClass('active');
        history.pushState("", document.title, "#contato");
    })
    .on('leave', function () {
        $nav.find('li').removeClass('active');
        $nav.find('#gestor-de-frotas').addClass('active');
    })
    .setTween(timeline)
    .addTo(new ScrollMagic.Controller());

    //Google Maps -------------------------------------------------
    var geocoder;
    var map;

    initialize();

    function initialize() {
        geocoder = new google.maps.Geocoder();
        var mapProp = {
            zoom: 16,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            draggable: false
        };

        map = new google.maps.Map(document.getElementById("gmap"), mapProp);

        codeAddress();
    }

    function codeAddress() {
        geocoder.geocode({'address': $('.address').data('address').trim()}, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                map.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location
                });
            }
        });
    }
    
    $("#abrirModalVideoTransportador").click(function() {
            $("#modalVideoTransportador").html('<iframe id="videoTransportador" src="https://www.youtube.com/embed/L4RpRPqTGaQ?autoplay=1" frameborder="0" allowfullscreen></iframe>');
            $("#modalVideoTransportador").show();
    });
    
    $("#modalVideoTransportador").click(function() {
        $("#modalVideoTransportador").html('');
        $("#modalVideoTransportador").hide();
    });

});